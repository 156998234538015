import {
  faClipboardList,
  faCodeMerge,
  faPrescriptionBottle,
  faSort,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../../components/Checkbox";
import DeviceRouteSelector from "../../../components/DeviceRouteSelector/DeviceRouteSelector";
import FacilityDispensingOrdersCard from "../../../components/FacilityDispensingOrdersCard2";
import ProductFieldController from "../../../controllers/ProductFieldController";
import ConfigService from "../../../services/ConfigService";
import DispensingOrderService from "../../../services/DispensingOrderService";
import PharmacyService from "../../../services/PharmacyService";
import RoleCheckService from "../../../services/RoleCheckService";
import ModalGen from "../../commonUI/ModalGen";
import DeviceCoverage from "../DeviceCoverage/DeviceCoverage";
import PackagingMethodFilter from "../PackagingMethodFilter/PackagingMethodFilter";
import PickList from "../PickList/PickList";
import SelectDevice from "../PickList/SelectDevice";
import PrintMedList from "../PrintMedList/PrintMedList";
import SiteHeader from "../SiteHeader/SiteHeader";
import DispensingOrdersDesktopView from "./DispensingOrdersDesktopView";
import DispensingOrdersMobileView from "./DispensingOrdersMobileView";

import styles from "./DispensingOrders2.module.css";

import { useMediaQuery } from "react-responsive";
import TrayPickList from "../../../components/TrayPickList/TrayPickList";
import { ScreenContext } from "../../../contexts/ScreenContext";

const DispensingOrders = ({ user }) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("dispensingOrders");
  const dialogRef = useRef(null);
  const blockRef = useRef(false);
  const productFieldControllerRef = useRef(new ProductFieldController());
  const facilitiesEl = useRef();
  const timerId = useRef(null);
  const [sites, setSites] = useState([]);
  const [selectedSiteIndex, setSelectedSiteIndex] = useState(null);
  const [fetchingPending, setFetchingPending] = useState(false);
  const [fetchingRouted, setFetchingRouted] = useState(false);
  const [fetchingPackaged, setFetchingPackaged] = useState(false);
  const [trayData, setTrayData] = useState([]);

  const [pendingDispensingOrders, setPendingDispensingOrders] = useState([]);
  const [routedDispensingOrders, setRoutedDispensingOrders] = useState([]);
  const [packagedDispensingOrders, setPackagedDispensingOrders] = useState([]);
  const [error, setError] = useState("");
  const [pendingDOSelection, setPendingDOSelection] = useState([]);
  const [printDispensingOrder, setPrintDispensingOrder] = useState(null);
  const [printPatients, setPrintPatients] = useState([]);
  const [printMedLists, setPrintMedLists] = useState([]);
  const [routedDOSelection, setRoutedDOSelection] = useState([]);
  const [packagedDOSelection, setPackagedDOSelection] = useState([]);
  const [verifiedDOSelection, setVerifiedDOSelection] = useState([]);
  const [selectAllPendingOrders, setSelectAllPendingOrders] = useState(false);
  const [orderDetailsDialog, setOrderDetailsDialog] = useState(null);
  const [showDeviceRouteSelector, setShowDeviceRouteSelector] = useState(null);
  const [draggedDispensingOrders, setDraggedDispensingOrders] = useState([]);
  const [selectedPackagingMethods, setSelectedPackagingMethods] = useState([]);
  const [packagingMethods, setPackagingMethods] = useState([]);
  const [packagingMethodsLookup, setPackagingMethodsLookup] = useState({});
  const [showCombineIcon, setShowCombineIcon] = useState(false);
  const [showGetDeviceModal, setShowGetDeviceModal] = useState(false);
  const [showPicklistModal, setShowGetPickListModal] = useState(false);
  const [showPrintMedListModal, setShowPrintMedListModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [currentTrayNeeds, setCurrentTrayNeeds] = useState(null);
  const [pendingSorted, setPendingSorted] = useState(false);
  const [routedSorted, setRoutedSorted] = useState(false);
  const [packagedSorted, setPackagedSorted] = useState(false);

  useEffect(() => {
    // Returning to print med list from facility redirection
    if (location.state?.previousState && sites.length) {
      const index = location.state.previousState.selectedSiteIndex;
      const dispensingOrder = location.state.previousState.dispensingOrder;
      navigate(location.pathname, {});

      fetchData(sites[index]);
      blockRef.current = true;
      DispensingOrderService.getPatients(user, sites[index], dispensingOrder)
        .then((patients) => {
          DispensingOrderService.getMedLists(
            user,
            sites[index],
            dispensingOrder,
            patients.map((patient) => patient._id)
          )
            .then((medLists) => {
              setSelectedSiteIndex(index);
              setPrintDispensingOrder(dispensingOrder);
              setPrintPatients(patients);
              setPrintMedLists(medLists);
              setShowPrintMedListModal(true);
            })
            .catch((error) => {
              setError(error);
              blockRef.current = false;
            });
        })
        .catch((error) => {
          setError(error);
          blockRef.current = false;
        });
      setShowPrintMedListModal(true);
    }
  }, [location, sites]);

  const fetchData = (site) => {
    setFetchingPending(true);
    DispensingOrderService.getDispensingOrdersForSite(user, site, "pending")
      .then((orders) => {
        setPendingDispensingOrders(orders);
        setFetchingPending(false);
      })
      .catch((error) => {
        setError(error);
        setFetchingPending(false);
      });
    setFetchingRouted(true);

    /*************************************************************************/
    //  Added by RwD, 12/27/2023
    DispensingOrderService.getBySite(user, site)
      .then((trayDataResults) => {
        setFetchingRouted(false);
        const trayDataResultsFiltered = trayDataResults.filter(
          (trayDataResult) => {
            return trayDataResult?.dispensingOrder?.state === "routed";
          }
        );
        setTrayData(trayDataResultsFiltered);
      })
      .catch((error) => {
        setError(error);
        setFetchingRouted(false);
      });
    /*************************************************************************/

    setFetchingRouted(true);

    DispensingOrderService.getDispensingOrdersForSite(user, site, "routed")
      .then((orders) => {
        setRoutedDispensingOrders(orders);
        setFetchingRouted(false);
      })
      .catch((error) => {
        setError(error);
        setFetchingRouted(false);
      });

    setFetchingPackaged(true);
    // specifying 'packaged' will fetch both partially_packaged and packaged items
    DispensingOrderService.getDispensingOrdersForSite(user, site, "packaged")
      .then((orders) => {
        setPackagedDispensingOrders(orders);
        setFetchingPackaged(false);
      })
      .catch((error) => {
        setError(error);
        setFetchingPackaged(false);
      });

    // a background operation to auto migrate state from 'packaged or partially_packaged' to 'delivered'
    DispensingOrderService.autoDeliverPackagedItems(user, site)
      .then()
      .catch((error) => setError(error));
  };

  // TIMER FOR REFRESH
  useEffect(() => {
    const site = sites[selectedSiteIndex];
    if (site) {
      fetchData(site);
    }
    setSelectedPackagingMethods([]);
    setSelectAllPendingOrders(false);
    setPendingDOSelection([]);
    let timerId = setInterval(() => {
      const site = sites[selectedSiteIndex];
      if (site && !blockRef.current) {
        fetchData(site);
      }
    }, 180000);
    return () => {
      clearInterval(timerId);
    };
  }, [selectedSiteIndex]);

  useEffect(() => {
    PharmacyService.list(user).then((sites) => {
      let filteredSites = [];
      if (user.isEnterprise) {
        sites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });

        filteredSites = sites;

        setSites(filteredSites);
      } else {
        filteredSites = sites
          .filter((site) => user.sites.some((o) => o._id === site._id))
          .map((site) => site);

        filteredSites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });

        setSites(filteredSites);
      }

      if (location.state?.selectedSite) {
        const index = filteredSites.findIndex(
          (o) => o._id === location.state.selectedSite._id
        );
        setSelectedSiteIndex(index);
      } else if (filteredSites.length > 0) {
        setSelectedSiteIndex(0);
      } else if (sites.length > 0) {
        setSelectedSiteIndex(0);
      }
    });
  }, [user]);

  // PACKAGING METHODS
  useEffect(() => {
    ConfigService.packagingMethods()
      .then((packagingMethods) => {
        setPackagingMethods(packagingMethods);
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  useEffect(() => {
    if (location.state?.selectedPackagingMethods) {
      setSelectedPackagingMethods(location.state?.selectedPackagingMethods);
    }
    // Limit selectedPackagingMethods to those that match the site devices
    // The packagingMethodsLookup describes which methods are valid for the selected site.
    else if (packagingMethods.length && Object.keys(packagingMethodsLookup)) {
      let tempSelectedPackagingMethods = packagingMethods.filter((obj) => {
        if (packagingMethodsLookup[obj.description]) {
          return obj;
        }
      });
      if (selectedPackagingMethods.length === 0) {
        setSelectedPackagingMethods(tempSelectedPackagingMethods);
      }
    } else {
      setSelectedPackagingMethods([]);
    }
  }, [packagingMethods, packagingMethodsLookup]);

  // COMBINE ORDERS SELECTION
  useEffect(() => {
    setShowCombineIcon(false);

    if (pendingDOSelection.length >= 2) {
      if (pendingDOSelection.every((p) => p.dispensingOrderId.includes("~"))) {
        const values = pendingDOSelection.map(
          (p) => p.dispensingOrderId.split("~")[0]
        );
        const allEquals = values.every((value) => value === values[0]);
        if (allEquals) {
          setShowCombineIcon(true);
        }
      }
    }
  }, [pendingDOSelection]);

  // ACTION MENU
  const handleAction = (action, dispensingOrder, arr) => {
    const temp = [...arr];
    const index = temp.findIndex((o) => o._id === dispensingOrder._id);
    switch (action.type) {
      case "review":
        navigate("pending", {
          state: {
            user: user,
            site: sites[selectedSiteIndex],
            dispensingOrder: dispensingOrder,
            selectedPackagingMethods: selectedPackagingMethods,
          },
        });
        break;
      case "review_routed":
        navigate("routed", {
          state: {
            user: user,
            site: sites[selectedSiteIndex],
            dispensingOrder: dispensingOrder,
            selectedPackagingMethods: selectedPackagingMethods,
          },
        });
        break;
      case "review_packaged":
        navigate("packaged", {
          state: {
            user: user,
            site: sites[selectedSiteIndex],
            dispensingOrder: dispensingOrder,
            selectedPackagingMethods: selectedPackagingMethods,
          },
        });
        break;
      case "remove":
        if (
          window.confirm(
            "Do you really want to delete this order?  This action cannot be undone."
          )
        ) {
          blockRef.current = true;
          temp.splice(index, 1);
          DispensingOrderService.resetOrder(
            user,
            sites[selectedSiteIndex],
            dispensingOrder,
            dispensingOrder.state
          )
            .then(() => {
              blockRef.current = false;
            })
            .catch((error) => {
              blockRef.current = false;
              setError(error);
            });

          setPendingDispensingOrders(temp);
        }
        break;
      case "sendToDevice":
        setShowDeviceRouteSelector({ ...dispensingOrder });
        break;
      case "printMedList":
        blockRef.current = true;
        DispensingOrderService.getPatients(
          user,
          sites[selectedSiteIndex],
          dispensingOrder
        )
          .then((patients) => {
            DispensingOrderService.getMedLists(
              user,
              sites[selectedSiteIndex],
              dispensingOrder,
              patients.map((patient) => patient._id)
            )
              .then((medLists) => {
                setPrintDispensingOrder(dispensingOrder);
                setPrintPatients(patients);
                setPrintMedLists(medLists);
                setShowPrintMedListModal(true);
              })
              .catch((error) => {
                setError(error);
                blockRef.current = false;
              });
          })
          .catch((error) => {
            setError(error);
            blockRef.current = false;
          });
        break;
      case "resetToRouted":
        blockRef.current = true;
        temp.splice(index, 1);
        DispensingOrderService.resetOrder(
          user,
          sites[selectedSiteIndex],
          dispensingOrder,
          dispensingOrder.state
        )
          .then(() => {
            blockRef.current = false;
          })
          .catch((error) => {
            setError(error);
            blockRef.current = false;
          });
        setPackagedDispensingOrders(temp);
        dispensingOrder.state = "routed";
        setRoutedDispensingOrders((prev) => {
          return [...prev, dispensingOrder];
        });
        break;
      case "resetToPending":
        blockRef.current = true;
        const origState = dispensingOrder.state;
        dispensingOrder.state = "pending";
        temp.splice(index, 1);
        DispensingOrderService.resetOrder(
          user,
          sites[selectedSiteIndex],
          dispensingOrder,
          dispensingOrder.state
        )
          .then(() => {
            blockRef.current = false;
          })
          .catch((error) => {
            setError(error);
            blockRef.current = false;
          });
        if (origState === "routed") {
          setRoutedDispensingOrders(temp);
        } else if (
          origState === "packaged" ||
          origState === "partially_packaged"
        ) {
          setPackagedDispensingOrders(temp);
        }
        setPendingDispensingOrders((prev) => {
          return [...prev, dispensingOrder];
        });
        break;
      default:
        console.log(
          `${action.type} not handled in DispensingOrderController.handleAction`
        );
    }

    setPendingDOSelection([]);
    setRoutedDOSelection([]);
    setPackagedDOSelection([]);
    setVerifiedDOSelection([]);
  };

  // FUNCTIONS
  const handleDoubleClickOrder = (dispensingOrder) => {
    if (dispensingOrder.type === "retail") {
      setOrderDetailsDialog(dispensingOrder);
      dialogRef.current.header = dispensingOrder.rxNumber;
      dialogRef.current.show();
    }
  };

  const getProgress = (dispensingOrder) => {
    return (
      (dispensingOrder.qtyDispensed / dispensingOrder.qtyNeeded).toFixed(1) *
      100
    );
  };

  const samePackagingMethods = () => {
    let t = [];
    for (let i = 0; i < pendingDOSelection.length; i++) {
      const type = pendingDOSelection[i].facility.packagingMethod?.type || "";
      if (
        t.length > 0 &&
        !t.some((o) => {
          return o === type;
        })
      ) {
        return false;
      }
      t.push(type);
    }
    return true;
  };

  const routeSelectedOrdersToDevice = async (device) => {
    const temp = [...pendingDispensingOrders];
    const selectedItems = [...pendingDOSelection];
    setPendingDOSelection([]);
    for (let i = 0; i < selectedItems.length; i++) {
      const dispensingOrder = selectedItems[i];
      if (dispensingOrder) {
        let dispOrder = pendingDispensingOrders.find(
          (o) => o._id === dispensingOrder._id
        );

        const index = temp.findIndex((o) => o._id === dispOrder._id);
        await DispensingOrderService.routeOrder(
          user,
          sites[selectedSiteIndex],
          device,
          dispOrder
        );
        temp.splice(index, 1);
        dispOrder.state = "routed";
        setRoutedDispensingOrders((prev) => {
          return [...prev, dispOrder];
        });
      }
    }
    setPendingDispensingOrders(temp);
  };

  const handleClickOrder = (dispensingOrder, e) => {
    let arr = [];
    switch (dispensingOrder.state) {
      case "pending":
        arr = [...pendingDOSelection];
        arr = modifyArray(arr, dispensingOrder);
        setPendingDOSelection(arr);
        setRoutedDOSelection([]);
        setPackagedDOSelection([]);
        setVerifiedDOSelection([]);
        break;
      case "routed":
        arr = [...routedDOSelection];
        arr = modifyArray(arr, dispensingOrder);
        setRoutedDOSelection(arr);
        setPendingDOSelection([]);
        setPackagedDOSelection([]);
        setVerifiedDOSelection([]);
        break;
      case "packaged":
      case "partially_packaged":
        arr = [...packagedDOSelection];
        arr = modifyArray(arr, dispensingOrder);
        setPackagedDOSelection(arr);
        setPendingDOSelection([]);
        setRoutedDOSelection([]);
        setVerifiedDOSelection([]);
        break;
      case "verified":
        arr = [...verifiedDOSelection];
        arr = modifyArray(arr, dispensingOrder);
        setVerifiedDOSelection(arr);
        setPendingDOSelection([]);
        setRoutedDOSelection([]);
        setPackagedDOSelection([]);
        break;
      default:
        break;
    }
  };

  const modifyArray = (arr, dispensingOrder) => {
    if (arr.some((item) => item._id === dispensingOrder._id)) {
      const index = arr.findIndex((item) => {
        return item._id === dispensingOrder._id;
      });

      if (index !== -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(dispensingOrder);
    }
    return arr;
  };

  const handlePackagingMethodsLookup = (lookupObject) => {
    return setPackagingMethodsLookup(lookupObject);
  };

  const handleTrayNeeds = async (dispensingOrder) => {
    setCurrentTrayNeeds(dispensingOrder);
  };

  // UI COMPONENTS
  const dispensingOrderQueueHeaderGroup = (state) => {
    return (
      <div className={styles.dispensingOrderQueueHeaderIcons}>
        {RoleCheckService.viewOrderPickList(user) &&
          ((state === "pending" && pendingDOSelection.length > 0) ||
            (state === "routed" && routedDOSelection.length > 0)) &&
          pendingDOSelection.length > 0 && (
            <div className={styles.dispensingOrderQueueHeaderIcon}>
              <FontAwesomeIcon
                icon={faClipboardList}
                color={"#FFAB2B"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowGetDeviceModal(true);
                }}
              />
            </div>
          )}
        {RoleCheckService.isSuperAdmin(user) && state === "pending" && (
          <div className={styles.dispensingOrderQueueHeaderIcon}>
            <FontAwesomeIcon
              icon={faUserSecret}
              color={"#562F7E"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("order-generation", {
                  state: {
                    user: user,
                    site: sites[selectedSiteIndex],
                    selectedPackagingMethods: selectedPackagingMethods,
                  },
                });
              }}
            />
          </div>
        )}
        {RoleCheckService.canCreateScripts(user) && state === "pending" && (
          <div className={styles.dispensingOrderQueueHeaderIcon}>
            <FontAwesomeIcon
              icon={faPrescriptionBottle}
              color={"#FFAB2B"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("retail-script", {
                  state: {
                    user: user,
                    site: sites[selectedSiteIndex],
                    selectedPackagingMethods: selectedPackagingMethods,
                  },
                });
              }}
            />
          </div>
        )}
        {state === "pending" && (
          <div className={styles.dispensingOrderQueueHeaderIcon}>
            {showCombineIcon && (
              <FontAwesomeIcon
                icon={faCodeMerge}
                color={"#FFAB2B"}
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  setFetchingPending(true);
                  await DispensingOrderService.combineOrders(
                    user,
                    pendingDOSelection
                  );
                  let arr = [...pendingDispensingOrders];
                  let index = arr.findIndex(
                    (o) => o._id === pendingDOSelection[0]._id
                  );
                  arr.splice(index, 1);
                  index = arr.findIndex(
                    (o) => o._id === pendingDOSelection[1]._id
                  );
                  arr.splice(index, 1);
                  setPendingDispensingOrders(arr);
                  setPendingDOSelection([]);
                  const site = sites[selectedSiteIndex];
                  fetchData(site);
                }}
              />
            )}
          </div>
        )}

        <div className={styles.dispensingOrderQueueHeaderIcon}>
          <FontAwesomeIcon
            icon={faSort}
            color={"#FFAB2B"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (state === "pending") {
                setPendingSorted(true);
              } else if (state === "routed") {
                setRoutedSorted(true);
              } else if (state === "packaged") {
                setPackagedSorted(true);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const selectDeviceComponent = () => {
    return (
      <ModalGen modalClassName={styles.modal}>
        <SelectDevice
          sites={sites}
          selectedSiteIndex={selectedSiteIndex}
          selectedDevice={selectedDevice}
          onHandleSetSelectedDevice={(str) => {
            setSelectedDevice(str);
          }}
          onHandleCloseGenPickListModal={() => {
            setShowGetDeviceModal(false);
            setPendingDOSelection([]);
          }}
          onHandleGeneratePickList={() => {
            setShowGetDeviceModal(false);
            setShowGetPickListModal(true);
          }}
          onHandleModal={(boolVal) => {
            setShowGetDeviceModal(boolVal);
            setSelectedDevice("");
          }}
        />
      </ModalGen>
    );
  };

  const getPickList = () => {
    if (RoleCheckService.viewOrderPickList(user) && showPicklistModal) {
      return (
        <PickList
          user={user}
          dispensingOrdersSelected={pendingDOSelection}
          selectedDevice={selectedDevice}
          onHandleSetSelectedDevice={(str) => {
            setSelectedDevice(str);
            setShowGetPickListModal(false);
          }}
          onHandleSetDispensingOrdersSelected={(arr) => {
            setPendingDOSelection(arr);
          }}
          onHandleSetActiveChildComponent={(str) => {}}
        />
      );
    }
  };

  const getPrintMedList = () => {
    console.log("getPrintMedList");
    if (RoleCheckService.viewOrderDetails(user) && showPrintMedListModal) {
      return (
        <ModalGen modalClassName={styles.modal}>
          <PrintMedList
            patients={printPatients}
            medLists={printMedLists}
            selectedSiteIndex={selectedSiteIndex}
            dispensingOrder={printDispensingOrder}
            onHandleClosePrintMedListModal={() => {
              setShowPrintMedListModal(false);
              setPrintDispensingOrder(null);
              setPrintPatients([]);
              setPrintMedLists([]);
              blockRef.current = false;
            }}
            onHandleModal={(boolVal) => {
              setShowPrintMedListModal(boolVal);
              if (!boolVal) {
                setPrintDispensingOrder(null);
                setPrintPatients([]);
                setPrintMedLists([]);
                blockRef.current = false;
              }
            }}
          />
        </ModalGen>
      );
    }
  };

  const getSiteHeader = (site, index) => {
    const isSelected = selectedSiteIndex === index;
    return (
      <SiteHeader
        siteIsSelected={isSelected}
        key={site._id}
        site={site}
        onSelect={() => setSelectedSiteIndex(index)}
        // onSelect2={smScreen ? handleVerifySelectionClicked() : () => {}}
        onSelect2={smScreen ? () => {} : () => {}}
        user={user}
        isSelected={isSelected}
        siteReplenishmentNeeds={[]}
        onDeviceReplenishment={(deviceNeed) => {
          const stockLocation = deviceNeed.stockLocation;
          navigate("/devices", {
            state: {
              stockLocation: stockLocation,
            },
          });
        }}
      />
    );
  };

  const getPendingColumn = (filterText) => {
    if (selectedSiteIndex !== null) {
      return (
        <div className={styles.dispensingOrderQueue}>
          <div className={styles.dispensingOrderQueueHeader}>
            <Checkbox
              labelName={""}
              isOn={selectAllPendingOrders}
              onCheck={(isOn) => {
                if (!isOn) {
                  setPendingDOSelection([]);
                } else {
                  let arr = [];
                  // check filter and only select if filter is on
                  for (let i = 0; i < pendingDispensingOrders.length; i++) {
                    const doi = pendingDispensingOrders[i];
                    if (
                      selectedPackagingMethods.some(
                        (o) => o.type === doi.facility?.packagingMethod?.type
                      )
                    ) {
                      arr.push(doi);
                    }
                  }
                  setPendingDOSelection(arr);
                }
                setSelectAllPendingOrders(isOn);
              }}
            />
            <p className={styles.dispensingOrderQueueTitle}>{"Pending"}</p>
            {fetchingPending && (
              <Loader type="Rings" color="#089BAB" height={50} width={50} />
            )}
            {dispensingOrderQueueHeaderGroup("pending")}
          </div>
          {/*    Facilities go here   */}

          <div
            id={"facilities"}
            className={styles.facilityColumn}
            ref={facilitiesEl}
          >
            {pendingDispensingOrders
              .filter((o) => {
                return (
                  (filterText
                    ? o.rxNumber
                        .toUpperCase()
                        .includes(filterText.toUpperCase()) ||
                      o.patientInfo.name
                        .toUpperCase()
                        .includes(filterText.toUpperCase())
                    : true) &&
                  selectedPackagingMethods.some(
                    (pm) => pm.type === o.facility?.packagingMethod?.type
                  )
                );
              })
              .sort((a, b) => {
                if (!pendingSorted) {
                  return 0;
                }
                if (a.patientInfo.lastName < b.patientInfo.lastName) {
                  return -1;
                }
                if (b.patientInfo.lastName < a.patientInfo.lastName) {
                  return 1;
                }
                return 0;
              })
              .map((dispensingOrder, index) => {
                // Do not assign the value for the map key to the array index.  This may cause a problem when a card is deleted (like from a combineOrder operation).  Assign a unique id to the key instead.
                return (
                  <FacilityDispensingOrdersCard
                    user={user}
                    dispensingOrder={dispensingOrder}
                    onDrag={(e, dispensingOrder) => {
                      e.preventDefault();
                      setDraggedDispensingOrders(pendingDOSelection);
                    }}
                    key={dispensingOrder.dispensingOrderId}
                    trayNeeds={[]}
                    onTrayNeeds={(trayNeeds) => {}}
                    patientCount={dispensingOrder.patientCount}
                    bagCount={dispensingOrder.qtyNeeded}
                    alertCount={0}
                    dueTime={0}
                    progress={0}
                    onAction={(action) => {
                      handleAction(
                        action,
                        dispensingOrder,
                        pendingDispensingOrders
                      );
                    }}
                    onClickOrder={handleClickOrder}
                    onDoubleClickOrder={() => {
                      handleDoubleClickOrder(dispensingOrder);
                    }}
                    isSelected={pendingDOSelection.some((o) => {
                      return o._id === dispensingOrder._id;
                    })}
                  />
                );
              })}
          </div>
        </div>
      );
    }
  };

  const getRoutedColumn = (filterText) => {
    if (selectedSiteIndex !== null) {
      return (
        <div className={styles.dispensingOrderQueue}>
          <div className={styles.dispensingOrderQueueHeader}>
            <p className={styles.dispensingOrderQueueTitle}>{"Routed"}</p>
            {fetchingRouted && (
              <Loader type="Rings" color="#089BAB" height={50} width={50} />
            )}
            {dispensingOrderQueueHeaderGroup("routed")}
          </div>
          {/*    Facilities go here   */}
          <div
            id={"facilities"}
            className={styles.facilityColumn}
            ref={facilitiesEl}
          >
            {routedDispensingOrders
              .filter((o) => {
                return (
                  (filterText
                    ? o.rxNumber
                        .toUpperCase()
                        .includes(filterText.toUpperCase()) ||
                      o.patientInfo.name
                        .toUpperCase()
                        .includes(filterText.toUpperCase())
                    : true) &&
                  selectedPackagingMethods.some(
                    (pm) => pm.type === o.facility?.packagingMethod?.type
                  )
                );
              })
              .sort((a, b) => {
                if (!routedSorted) {
                  return 0;
                }
                if (a.patientInfo.lastName < b.patientInfo.lastName) {
                  return -1;
                }
                if (b.patientInfo.lastName < a.patientInfo.lastName) {
                  return 1;
                }
                return 0;
              })
              .map((dispensingOrder, index) => {
                /*************************************************************************/
                // Added by RWD on 12/27/2023
                let trayDataObj = null;
                const dispensingOrderTrayDataIndex = trayData.findIndex(
                  (trayDataObj) => {
                    return (
                      trayDataObj?.dispensingOrder?._id === dispensingOrder?._id
                    );
                  }
                );

                if (dispensingOrderTrayDataIndex !== -1) {
                  trayDataObj = trayData[dispensingOrderTrayDataIndex];
                }
                /*************************************************************************/

                // Do not assign the value for the map key to the array index.  This may cause a problem when a card is deleted (like from a combineOrder operation).  Assign a unique id to the key instead.

                return (
                  <FacilityDispensingOrdersCard
                    user={user}
                    dispensingOrder={dispensingOrder}
                    site={sites[selectedSiteIndex]}
                    trayDataObj={trayDataObj}
                    onDrag={() => {}}
                    key={dispensingOrder.dispensingOrderId}
                    trayNeeds={dispensingOrder.trayCount}
                    onTrayNeeds={() => {
                      handleTrayNeeds(dispensingOrder);
                    }}
                    patientCount={dispensingOrder.patientCount}
                    bagCount={dispensingOrder.qtyNeeded}
                    alertCount={0}
                    dueTime={0}
                    progress={0}
                    onAction={(action) => {
                      handleAction(
                        action,
                        dispensingOrder,
                        routedDispensingOrders
                      );
                    }}
                    onClickOrder={() => {}}
                    onDoubleClickOrder={() => {
                      handleDoubleClickOrder(dispensingOrder);
                    }}
                    isSelected={false}
                  />
                );
              })}
          </div>
        </div>
      );
    }
  };

  const getPackagedColumn = (filterText) => {
    if (selectedSiteIndex !== null) {
      return (
        <div className={styles.dispensingOrderQueue}>
          <div className={styles.dispensingOrderQueueHeader}>
            <p className={styles.dispensingOrderQueueTitle}>{"Packaged"}</p>
            {fetchingPackaged && (
              <Loader type="Rings" color="#089BAB" height={50} width={50} />
            )}
            {dispensingOrderQueueHeaderGroup("packaged")}
          </div>
          {/*    Facilities go here   */}
          <div
            id={"facilities"}
            className={styles.facilityColumn}
            ref={facilitiesEl}
          >
            {packagedDispensingOrders
              .filter((o) => {
                return (
                  (filterText
                    ? o.rxNumber
                        .toUpperCase()
                        .includes(filterText.toUpperCase()) ||
                      o.patientInfo.name
                        .toUpperCase()
                        .includes(filterText.toUpperCase())
                    : true) &&
                  selectedPackagingMethods.some(
                    (pm) => pm.type === o.facility?.packagingMethod?.type
                  )
                );
              })
              .sort((a, b) => {
                if (!packagedSorted) {
                  return 0;
                }
                if (a.patientInfo.lastName < b.patientInfo.lastName) {
                  return -1;
                }
                if (b.patientInfo.lastName < a.patientInfo.lastName) {
                  return 1;
                }
                return 0;
              })
              .map((dispensingOrder, index) => {
                // Do not assign the value for the map key to the array index.  This may cause a problem when a card is deleted (like from a combineOrder operation).  Assign a unique id to the key instead.
                return (
                  <FacilityDispensingOrdersCard
                    user={user}
                    onDrag={() => {}}
                    key={dispensingOrder.dispensingOrderId}
                    trayNeeds={[]}
                    onTrayNeeds={(trayNeeds) => {}}
                    dispensingOrder={dispensingOrder}
                    patientCount={dispensingOrder.patientCount}
                    bagCount={dispensingOrder.qtyNeeded}
                    alertCount={0}
                    dueTime={0}
                    progress={getProgress(dispensingOrder)}
                    onAction={(action) => {
                      handleAction(
                        action,
                        dispensingOrder,
                        packagedDispensingOrders
                      );
                    }}
                    onClickOrder={() => {}}
                    onDoubleClickOrder={() => {
                      handleDoubleClickOrder(dispensingOrder);
                    }}
                    isSelected={false}
                  />
                );
              })}
          </div>
        </div>
      );
    }
  };

  const getPackagingMethodWidget = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PackagingMethodFilter
          packagingMethodsLookup={packagingMethodsLookup}
          handlePackagingMethodsLookup={handlePackagingMethodsLookup}
          selectedSite={sites[selectedSiteIndex]}
          packagingMethods={packagingMethods}
          selectedPackagingMethods={selectedPackagingMethods}
          onChange={(temp) => {
            setSelectedPackagingMethods(temp);
          }}
          siteIsSelected={selectedSiteIndex != null}
        />
      </div>
    );
  };

  const getDeviceCoverageWidget = () => {
    if (samePackagingMethods()) {
      return (
        <DeviceCoverage
          user={user}
          site={sites[selectedSiteIndex]}
          dispensingOrder={pendingDOSelection[0]}
          showBlisterPackagers={selectedPackagingMethods.some((o) => {
            return o.type === "BLISTER_CARD";
          })}
          showPouchPackagers={selectedPackagingMethods.some((o) => {
            return o.type === "POUCH";
          })}
          showVialFillers={selectedPackagingMethods.some((o) => {
            return o.type === "VIAL";
          })}
          onDrop={async (e, device) => {
            e.stopPropagation(); // Stops some browsers from redirecting.
            e.preventDefault();
            const temp = [...pendingDispensingOrders];
            for (let i = 0; i < draggedDispensingOrders.length; i++) {
              const draggedDispensingOrder = draggedDispensingOrders[i];
              if (
                draggedDispensingOrder &&
                RoleCheckService.routeOrders(user)
              ) {
                let dispOrder = pendingDispensingOrders.find(
                  (o) => o._id === draggedDispensingOrder._id
                );
                const index = temp.findIndex((o) => o._id === dispOrder._id);
                await DispensingOrderService.routeOrder(
                  user,
                  sites[selectedSiteIndex],
                  device,
                  dispOrder
                );
                temp.splice(index, 1);
                dispOrder.state = "routed";
                setRoutedDispensingOrders((prev) => {
                  return [...prev, dispOrder];
                });
              }
            }
            setPendingDispensingOrders(temp);
            setDraggedDispensingOrders([]);
            setPendingDOSelection([]);
          }}
          onClick={async (device) => {
            if (RoleCheckService.routeOrders(user)) {
              await routeSelectedOrdersToDevice(device);
            }
          }}
        />
      );
    }
  };

  const getDeviceRouteSelector = () => {
    return (
      <DeviceRouteSelector
        user={user}
        dispensingOrder={showDeviceRouteSelector}
        site={sites[selectedSiteIndex]}
        onRoute={async (dispensingOrder, device) => {
          const temp = [...pendingDispensingOrders];
          const index = temp.findIndex((o) => o._id === dispensingOrder._id);
          await DispensingOrderService.routeOrder(
            user,
            sites[selectedSiteIndex],
            device,
            dispensingOrder
          );
          setShowDeviceRouteSelector(null);
          temp.splice(index, 1);
          setPendingDispensingOrders(temp);
          dispensingOrder.state = "routed";
          setRoutedDispensingOrders((prev) => {
            return [...prev, dispensingOrder];
          });
        }}
        onClose={() => {
          setShowDeviceRouteSelector(null);
        }}
      />
    );
  };

  const handleError = (err) => {
    setError(err);
  };

  const handlePendingDOSelection = (selection) => {
    setPendingDOSelection(selection);
  };

  const handleSelectAllPendingOrders = (boolVal) => {
    setSelectAllPendingOrders(boolVal);
  };

  const handleOrderDetailsDialog = (details) => {
    setOrderDetailsDialog(details);
  };

  if (smScreen) {
    return (
      <DispensingOrdersMobileView
        sites={sites}
        t={t}
        error={error}
        onError={handleError}
        getSiteHeader={getSiteHeader}
        dialogRef={dialogRef}
        dispensingOrderQueueHeaderGroup={dispensingOrderQueueHeaderGroup}
        getPendingColumn={getPendingColumn}
        getRoutedColumn={getRoutedColumn}
        getPackagedColumn={getPackagedColumn}
        getPackagingMethodWidget={getPackagingMethodWidget}
        getDeviceCoverageWidget={getDeviceCoverageWidget}
        productFieldControllerRef={productFieldControllerRef}
        selectedSiteIndex={selectedSiteIndex}
        fetchingPending={fetchingPending}
        pendingDispensingOrders={pendingDispensingOrders}
        pendingDOSelection={pendingDOSelection}
        onPendingDOSelection={handlePendingDOSelection}
        selectAllPendingOrders={selectAllPendingOrders}
        onSelectAllPendingOrders={handleSelectAllPendingOrders}
        orderDetailsDialog={orderDetailsDialog}
        onOrderDetailsDialog={handleOrderDetailsDialog}
        showDeviceRouteSelector={showDeviceRouteSelector}
        getDeviceRouteSelector={getDeviceRouteSelector}
        showGetDeviceModal={showGetDeviceModal}
        selectDeviceComponent={selectDeviceComponent}
        showPicklistModal={showPicklistModal}
        getPickList={getPickList}
        showPrintMedListModal={showPrintMedListModal}
        getPrintMedList={getPrintMedList}
      />
    );
  } else if (currentTrayNeeds) {
    return (
      <TrayPickList
        user={user}
        data={currentTrayNeeds}
        onClose={() => setCurrentTrayNeeds(null)}
      />
    );
  } else {
    return (
      <DispensingOrdersDesktopView
        sites={sites}
        t={t}
        error={error}
        onError={handleError}
        getSiteHeader={getSiteHeader}
        dialogRef={dialogRef}
        getPendingColumn={getPendingColumn}
        getRoutedColumn={getRoutedColumn}
        getPackagedColumn={getPackagedColumn}
        getPackagingMethodWidget={getPackagingMethodWidget}
        getDeviceCoverageWidget={getDeviceCoverageWidget}
        productFieldControllerRef={productFieldControllerRef}
        pendingDOSelection={pendingDOSelection}
        orderDetailsDialog={orderDetailsDialog}
        onOrderDetailsDialog={handleOrderDetailsDialog}
        showDeviceRouteSelector={showDeviceRouteSelector}
        getDeviceRouteSelector={getDeviceRouteSelector}
        showGetDeviceModal={showGetDeviceModal}
        selectDeviceComponent={selectDeviceComponent}
        showPicklistModal={showPicklistModal}
        getPickList={getPickList}
        showPrintMedListModal={showPrintMedListModal}
        getPrintMedList={getPrintMedList}
      />
    );
  }
};
export default DispensingOrders;
